'use client'

import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Button, Cell, Dialog, Spacer, Text } from '@vinted/web-ui'
import { QuestionCircle24 } from '@vinted/monochrome-icons'

import SeparatedList from 'components/SeparatedList'
import ScrollableArea from 'components/ScrollableArea'
import { ItemSelection } from 'components/Bumps'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { clickEvent, viewEvent, viewScreenEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'

import { BumpOptionModel, BumpOptionsModel } from 'types/models'

import BumpOptionList from './BumpOptionList'

enum ReviewOrderActionTargetDetails {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

type Props = {
  canAddItems?: boolean
  show: boolean
  isOrderLoading: boolean
  isBumpOptionsLoading: boolean
  bumpOptions?: BumpOptionsModel
  selectedOption: BumpOptionModel | null
  onSelectedOptionChange: (selectedBumpOption: BumpOptionModel) => void
  onAddButtonClick: () => void
  onConfirm: () => void
  onCancel: () => void
  onHelpButtonClick: () => void
}

const BumpSelectionConfirmationModal = ({
  canAddItems = true,
  show,
  isOrderLoading,
  isBumpOptionsLoading,
  bumpOptions,
  selectedOption,
  onSelectedOptionChange,
  onCancel,
  onConfirm,
  onAddButtonClick,
  onHelpButtonClick,
}: Props) => {
  const { locale } = useIntl()

  const isEmptyBumpOrderFixEnabled = useFeatureSwitch('empty_bump_order_fix_web')

  const { track } = useTracking()
  const translate = useTranslate('bump_items.selection_confirmation_modal')
  const a11yTranslate = useTranslate('bump_items.a11y')

  const orderItemsCount = selectedOption?.pushUpOrderItems.length

  useEffect(() => {
    if (!show) return

    track(
      viewScreenEvent({
        screen: Screen.PushUpOrderReview,
      }),
    )
  }, [show, track])

  useEffect(() => {
    if (!isEmptyBumpOrderFixEnabled) return
    if (orderItemsCount === undefined) return

    track(
      viewEvent({
        screen: Screen.PushUpOrderReview,
        target: ViewableElement.BumpPreCheckoutReviewOrderAction,
        targetDetails: orderItemsCount
          ? ReviewOrderActionTargetDetails.Enabled
          : ReviewOrderActionTargetDetails.Disabled,
      }),
    )
  }, [orderItemsCount, isEmptyBumpOrderFixEnabled, track])

  function handleConfirm(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    if (isOrderLoading || isBumpOptionsLoading) {
      event.preventDefault()

      return
    }

    if (isOrderLoading || !selectedOption) return

    onConfirm()

    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.ReviewPushupOrder,
        targetDetails: selectedOption.days.toString(),
      }),
    )
  }

  function handleCancel() {
    onCancel()

    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.CloseScreen,
      }),
    )
  }

  function handleAddButtonClick() {
    onAddButtonClick()
    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.MultiplePushUp,
      }),
    )
  }

  function handleHelpButtonClick() {
    onHelpButtonClick()
    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.PushUpLearnMode,
      }),
    )
  }

  function handleBumpOptionChange(selectedBumpOption: BumpOptionModel) {
    onSelectedOptionChange(selectedBumpOption)
    track(
      clickEvent({
        screen: Screen.PushUpOrderReview,
        target: ClickableElement.PushUpDurationChange,
        targetDetails: JSON.stringify({
          dutation: selectedBumpOption.days.toString(),
          is_international: selectedBumpOption.international,
        }),
      }),
    )
  }

  function getOrderPriceLabel() {
    return selectedOption ? formatCurrencyAmount(selectedOption.payable, locale) : ''
  }

  function renderTitle() {
    return (
      <Cell
        styling={Cell.Styling.Narrow}
        prefix={<Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.X3Large} />}
        suffix={
          <Button
            iconName={QuestionCircle24}
            styling={Button.Styling.Flat}
            onClick={handleHelpButtonClick}
            testId="bump-help-button"
            aria={{ 'aria-label': a11yTranslate('actions.learn_more') }}
          />
        }
      >
        <div className="u-text-center">
          <Text
            id="bump_selection_modal_title"
            type={Text.Type.Title}
            alignment={Text.Alignment.Center}
            text={translate('title')}
          />
        </div>
      </Cell>
    )
  }

  function renderMotivation() {
    if (!bumpOptions?.motivation) return null

    return (
      <Cell styling={Cell.Styling.Narrow}>
        <Text
          format
          type={Text.Type.Subtitle}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          text={bumpOptions.motivation.description}
          theme={bumpOptions.motivation.highlight ? 'success' : undefined}
        />
      </Cell>
    )
  }

  function renderBumpOptions() {
    if (!bumpOptions?.options.length || !selectedOption) return null

    return (
      <BumpOptionList
        bumpOptions={bumpOptions.options}
        selectedOptionId={selectedOption.id}
        onSelect={handleBumpOptionChange}
      />
    )
  }

  function renderSalesTaxNote() {
    if (!selectedOption?.salesTax) return null

    return (
      <Text
        alignment={Text.Alignment.Center}
        text={translate('duration.details.sales_tax_note')}
        testId="bump-selection-confirmation-modal--sales-tax-note"
        width={Text.Width.Parent}
      />
    )
  }

  function renderDurationOptionsInfo() {
    return (
      <div className="u-ui-padding-large u-padding-top-none">
        <SeparatedList separator={<Spacer orientation={Spacer.Orientation.Horizontal} />}>
          {renderBumpOptions()}
          {renderSalesTaxNote()}
        </SeparatedList>
      </div>
    )
  }

  function renderBody() {
    return (
      <div className="u-fill-width">
        <ItemSelection
          canAddItems={canAddItems}
          orderItems={selectedOption?.pushUpOrderItems}
          onAddButtonClick={handleAddButtonClick}
        />
        {renderDurationOptionsInfo()}
      </div>
    )
  }

  function renderActions() {
    const amount = getOrderPriceLabel()
    const isEmptyOrder = isEmptyBumpOrderFixEnabled && !orderItemsCount

    return (
      <Cell>
        <Button
          isLoading={isOrderLoading || isBumpOptionsLoading}
          disabled={!selectedOption || isBumpOptionsLoading || isEmptyOrder}
          styling={Button.Styling.Filled}
          text={translate('actions.review_order', { amount })}
          onClick={handleConfirm}
          testId="bump-review-order-button"
        />
        <Spacer />
        <Button
          styling={Button.Styling.Flat}
          text={translate('actions.cancel')}
          aria={{ 'aria-label': a11yTranslate('actions.close') }}
          onClick={handleCancel}
        />
      </Cell>
    )
  }

  return (
    <Dialog
      aria={{ labelledby: 'bump_selection_modal_title' }}
      show={show}
      hasScrollableContent
      testId="bump-selection-confirmation-modal"
    >
      <div className="u-fill-width">
        {renderTitle()}
        <ScrollableArea>
          {renderMotivation()}
          {renderBody()}
        </ScrollableArea>
        {renderActions()}
      </div>
    </Dialog>
  )
}

export default BumpSelectionConfirmationModal
